<template>
  <div id="unmarked-ates">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <div class="columns is-multiline">
        <div class="column is-12">
          <b-field label="Term">
            <b-select
              id="term-id"
              v-model="termId"
              placeholder="Select a option"
              expanded
            >
              <option v-for="term in terms" :key="term.id" :value="term.id">
                {{ term.sessionTitle }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-12">
          <b-table :data="ateMarks" hoverable>
            <b-table-column
              field="#"
              label="#"
              width="40"
              numeric
              v-slot="props"
            >
              {{ ateMarks.indexOf(props.row) + 1 }}
            </b-table-column>

            <b-table-column label="School class" v-slot="props">
              {{ props.row.schoolClassName }}
            </b-table-column>

            <b-table-column label="Subject" v-slot="props">
              {{ props.row.subjectName }}
            </b-table-column>

            <b-table-column label="Number In CLass" v-slot="props">
              {{ props.row.numberOfStudents }}
            </b-table-column>

            <b-table-column label="Marked" v-slot="props">
              {{ props.row.ateMarks.filter((item) => item.marked).length }}
            </b-table-column>

            <b-table-column label="Unmarked" v-slot="props">
              {{ props.row.ateMarks.filter((item) => !item.marked).length }}
            </b-table-column>

            <b-table-column label="Actions" width="160" v-slot="props">
              <router-link
                :to="`/school/${schoolId}/ate_scores/${props.row.ateMarks[0].ate.id}`"
                class="button is-info"
                >Scores</router-link
              >
            </b-table-column>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <fa-icon icon="info-circle" class="fa-5x" />
                  </p>
                  <p>
                    No data to display here. Continue with a New ATE operation
                  </p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { fetchUser } from '@/assets/js/app_info.js'
import TERMS from '../../../graphql/term/Terms.gql'

export default {
  name: 'UnmarkedAtes',
  data() {
    return {
      pageTitle: 'ATE Unmarked',
      schoolId: null,
      unmarkedAtes: [],
      ateMarks: [],
      terms: [],
      termId: '',
    }
  },
  watch: {
    termId() {
      this.fetchAteMarks()
    },
    terms(data) {
      this.termId = data[0].id
    },
    unmarkedAtes(data) {
      this.ateMarks = []
      const schoolClassNames = data.currentTermAteMarks.map(
        (item) => item.ate.schoolClass.name
      )
      Array.from(new Set(schoolClassNames)).forEach((schoolClassName) => {
        const ateMarks = data.currentTermAteMarks.filter(
          (item) => item.ate.schoolClass.name === schoolClassName
        )

        const subjects = ateMarks.map((item) => item.ate.subject.name)

        Array.from(new Set(subjects)).forEach((subjectName) => {
          const ateMarksBySubjects = ateMarks.filter(
            (item) => item.ate.subject.name === subjectName
          )

          this.ateMarks.push({
            schoolClassName: schoolClassName,
            numberOfStudents:
              ateMarks[0].ate.schoolClass.assignedClass.numberOfStudents,
            subjectName: subjectName,
            ateMarks: ateMarksBySubjects,
          })
        })
      })
    },
  },
  methods: {
    fetchAteMarks() {
      this.$apollo.addSmartQuery('unmarkedAtes', {
        query: gql`
          query AtesUnmarkedQuery($id: ID!, $termId: ID) {
            unmarkedAtes(id: $id, termId: $termId) {
              currentTermAteMarks {
                id
                ate {
                  id
                  schoolClass {
                    name
                    assignedClass {
                      numberOfStudents
                    }
                  }
                  subject {
                    name
                  }
                }
                marked
              }
              termId
            }
          }
        `,
        variables: {
          id: this.user.id,
          termId: this.termId,
        },
      })

      this.$apollo.queries.unmarkedAtes.refetch()
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)

    fetchUser().then((user) => {
      this.user = user

      this.$apollo.addSmartQuery('terms', {
        query: TERMS,
        variables: {
          schoolId: parseInt(this.schoolId),
        },
      })

      this.$apollo.queries.terms.refetch()

      this.$store.commit('setSubMenus', [
        {
          name: 'New Ate',
          route: `/school/${user.school_id}/new_ate`,
        },
      ])
    })
  },
}
</script>
